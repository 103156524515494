import React, { useState, useEffect } from 'react';
import axios from 'axios';
import techno from "../../Assets/techno-owl.png";
import './result.css'
const Results = () => {
  const baseURL = process.env.NODE_ENV === "production" ? "https://technothlon.techniche.org.in/api/" : "http://localhost:3001/api/";
  const redirectUrl = process.env.NODE_ENV === "production" ? "https://technothlon.techniche.org.in/login" : "http://localhost:3000/login";
  const [results, setResults] = useState("");
  const [user, setUser] = useState();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`${baseURL}uploadcsv/user`, {
          withCredentials: true
        });
        setUser(response.data.user);
      } catch (err) {
        console.log('Error fetching user details:', err);
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (user && user.roll) {
      const fetchResults = async () => {
        try {
          const response = await axios.get(`${baseURL}uploadcsv/results?Roll=${user.roll}`, {
            withCredentials: true
          });
          if (response.data && response.data.user) {
            setResults(response.data.user);
        } else {
            setResults({ Rank: 'NA' }); // If user data is not found, set rank to 'NA'
        }
        } catch (err) {
          console.log('Error fetching results:', err);
          setResults({ Rank: 'NA' }); // Handle errors by setting rank to 'NA'
        }
      };

      fetchResults();
    }
  }, [user]);

  return (
    <div className="Results-main">
      <div className="roll"><p>{user?.roll}</p><img src={techno} alt="" /></div>
      <div className="content">
        <div className="para">
          <p>TECHNOTHLON'24 RESULT</p>
        </div>
        <div className="AIR">
          <p>Congratulations...You have secured</p>
          <p><b>AIR: {results.Rank}</b></p>
        </div>
        
      </div>
    </div>
  );
};

export default Results;
