import React, { useState, useEffect } from 'react';
import techno from "../../Assets/techno-owl.png";
import './profile.css';
import './updates.css';
import axios from 'axios';
import { saveAs } from 'file-saver';

const Updates = () => {
  const baseURL = process.env.NODE_ENV === "production" ? "https://technothlon.techniche.org.in/api/" : "http://localhost:3001/api/";
  const redirectUrl = process.env.NODE_ENV === "production" ? "https://technothlon.techniche.org.in/" : "http://localhost:3000/";

  const [user, setUser] = useState('');

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`${baseURL}uploadcsv/user`, {
          withCredentials: true
        });
        setUser(response.data.user);
      } catch (err) {
        console.log('Error fetching user details:', err);
      }
    };

    fetchUserDetails();
  }, []);
  
  // const createAndDownloadPdf = async () => {
  //   try {
  //       await axios.post(`${baseURL}uploadcsv/create-pdf`, {roll:(user.roll||user.rollNumber),name1:user.name1,name2:user.name2});
  //       const rollno = user.roll || user.rollNumber;
  //       const res = await axios.get(`${baseURL}uploadcsv/fetch-pdf`, { responseType: 'blob' });
  //       const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
  //       saveAs(pdfBlob, `${rollno}_Technothlon-2024.pdf`);
  //   } catch (error) {
  //     window.alert('Your Admit Card is being generated. Please try again in a few hours.')
  //       console.error('Error creating or fetching PDF', error);
  //   }
  // };

  return (
    <div className="Updates-main main">
      <div className="roll">
        <p>{user.roll || user.rollNumber}</p>
        <img src={techno} alt="Technothlon Owl" />
      </div>
      <div className='profile-heading'>Updates</div>
      <div className='heading-banner'>General Instructions</div>
      <div className='updates-content-wrapper'>
        <div className='updates-content-heading'>TECHNOTHLON 2024 UPDATES</div>
        <div className='updates-contents'>
          <ul>
            <li>The results declared by our team would be final and no queries regarding re-evaluation will be entertained.</li>
            <li>But you can always write to us in case you find any error/issue in any of the questions.</li>
            <li>Follow our social handles for more updates.</li>
            {/* <li style={{ color: "red" }}>If your admit card is not downloading, please wait for a while. It will be available soon.</li> */}
          </ul>
        </div>
        {/* <div className='get-admitCard-wrapper'>
          <button 
            style={{
              backgroundColor: "green", 
              color: "white",
              height: "50px", 
              width: "200px",
              border: "none",
              borderRadius: "5px",
              fontSize: "16px",
              cursor: "pointer",
              marginTop: "40px"
            }} 
            onClick={createAndDownloadPdf}
          >
            <b>Download Admit Card</b>
          </button> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Updates;
